<script lang="ts" setup>
import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import type {
	EntityData,
	EntityXProductData,
	PageSectionResponse,
	ProductsData,
} from '@laam/cms-shared';
import { fetchSections } from '~/data/pageschema';
import {
	keepPreviousData,
	useInfiniteQuery,
	useQuery,
} from '@tanstack/vue-query';
import {
	CategoryGrid,
	EntityGrid,
	ProductsGrid,
	CategoryCarousel,
	CategoryCarouselScrollable,
	CategoryGridScrollable,
	EntityCarouselV2,
	ProductsCarouselV2,
	EntityCarouselScrollable,
} from '@laam/ui/cms';
import EntityCard from '~/components/homepage/octane/EntityCard.vue';
import HeroBanner from '~/components/homepage/octane/HeroBanner.vue';
import EntityXProductsCarousel from '~/components/homepage/octane/EntityXProductsCarousel.vue';
import EntityXProductsGrid from '~/components/homepage/octane/EntityXProductsGrid.vue';
import ProductCard from '~/components/ProductCard.vue';
import CategoryCard from '~/components/homepage/octane/CategoryCard.vue';
import TrendingProducts from '~/components/homepage/TrendingProducts.vue';
import MiniBanner from '~/components/homepage/octane/MiniBanner.vue';
import { type MetaDataKeys, META_DATA } from '~/utils/constants';
import useSeoSchema from '~/utils/inject-webpage-schema';
import { cast } from '~/utils/helpers';
import { logHighLatency } from '~/utils/log-high-latency';
import { EU_COUNTRIES } from '~/utils/enums';

definePageMeta({
	validate: (route) => {
		// First check if it's not a file
		const isFile =
			/^.*\.(jpg|jpeg|png|gif|ico|json|rss|xml|svg|js|css|mjs|woff|woff2|pdf|xml)$/.test(
				route.fullPath,
			);
		if (isFile) return false;
		return true;
	},
});

const route = useRoute();

const pageRequestId = uuidv4();

const store = useGlobalStore();
const { storeType, criticalLatency } = storeToRefs(store);
const { deliverToCountry } = storeToRefs(useCountryCode());

const pageIdentifier = computed(() => {
	if (!(route.name!.toString() === 'identifier')) return;
	if (!route.params) return;
	if (!route.params.identifier) {
		return 'homepage';
	} else if (Array.isArray(route.params.identifier)) {
		return route.params.identifier.join('-');
	} else return route.params.identifier;
});

const { $eventClient } = useNuxtApp();

const { countryCode } = useCountryCode();

const hasPageIdentifier = computed(() => !!pageIdentifier.value);

const globalCutoffConfig = inject('globalCutoffConfig') as any;

const {
	data: server,
	error,
	suspense,
} = useQuery({
	queryKey: ['server_page', pageIdentifier],
	queryFn: () =>
		fetchSections(
			pageRequestId,
			pageIdentifier.value!,
			undefined,
			countryCode,
			storeType.value!,
		),
	enabled: hasPageIdentifier,
	placeholderData: keepPreviousData,
});

const difference = ref(-1);
provide('difference', difference);

onServerPrefetch(async () => {
	const firstPage = await suspense();
	const latencies = criticalLatency.value;
	latencies[2] = firstPage.data ? firstPage.data.latency : -1;
	logHighLatency(latencies);
});

await suspense();

if (error.value) {
	throw createError({
		statusCode: 404,
		message: 'Page not found',
		data: {
			url: route.fullPath,
			error: error.value,
		},
		fatal: true,
	});
}

const {
	data: pagesData,
	hasNextPage,
	isFetchingNextPage,
	fetchNextPage,
} = useInfiniteQuery<PageSectionResponse>({
	queryKey: ['pageSchema', server.value?.pageRequestId],
	queryFn: ({ pageParam }) =>
		fetchSections(
			server.value!.pageRequestId,
			pageIdentifier.value!,
			pageParam as string,
			countryCode,
			storeType.value!,
		),
	getNextPageParam: (lastPage) => {
		if (!lastPage) {
			return null;
		}
		return lastPage.cursor == '' ? null : lastPage.cursor;
	},
	initialData: {
		pages: [server.value!],
		pageParams: [server?.value?.cursor!],
	},
	initialPageParam: 1,
	refetchOnWindowFocus: false,
	staleTime: 15 * 60 * 1000, // 15 minutes
	enabled: !!server.value,
});

watch(isFetchingNextPage, (isFetchingNextPage) => {
	if (isFetchingNextPage)
		$eventClient.sendEvent('homepage-pagination', {
			page_number: (pagesData.value?.pages.length || 0) + 1,
			location: window.location.href,
		});
});

const handleNextPage = async () => {
	if (hasNextPage.value && !isFetchingNextPage.value) {
		fetchNextPage();
	}
};

const { $cathodeClient } = useNuxtApp();
useSeoSchema(pageIdentifier.value);

onMounted(() => {
	$cathodeClient.queueEvent('page_load', {
		source: `${window.location.origin}${route.path}`,
		statusCode: 200,
		page: 'Homepage',
		referrer: document.referrer,
	});
});

if (
	storeType.value !== 'OCTANE' &&
	pageIdentifier.value &&
	['homepage', 'women', 'men', 'kids'].includes(pageIdentifier.value)
) {
	useSeoMeta({
		title: META_DATA[pageIdentifier.value as MetaDataKeys].title,
		ogTitle: META_DATA[pageIdentifier.value as MetaDataKeys].title,
		description: META_DATA[pageIdentifier.value as MetaDataKeys].description,
		ogDescription: META_DATA[pageIdentifier.value as MetaDataKeys].description,
		titleTemplate: null,
	});
}

useInfiniteSroll(handleNextPage, 0.6);

const Logan = useLoganStore();

provide('storeType', storeType);

const nodeIdMapToIdentifier = {
	homepage: [1, 21, 41, 54],
	women: [1],
	men: [21],
	kids: [41, 54],
};

const getVerticalIndex = (pageIndex: number, sectionIndex: number) => {
	if (!pagesData.value) return 0;
	let pageCounter = 0;
	let countedSections = 0;
	while (pageCounter < pageIndex) {
		countedSections += pagesData.value.pages[pageCounter]!.sections.length;
		pageCounter++;
	}
	return countedSections + sectionIndex;
};

const cdnImageUrls = ref({
	desktop: {
		default:
			'https://cdn.shopify.com/s/files/1/0618/4619/2349/files/desktop-1_2_-174219143432128.png',
		kli: 'https://cdn.shopify.com/s/files/1/2337/7003/files/desktop-174272132659457.png',
		lhr: 'https://cdn.shopify.com/s/files/1/2337/7003/files/desktop-lahore-only-174272126161455.png',
	},

	mobile: {
		default:
			'https://cdn.shopify.com/s/files/1/0618/4619/2349/files/mobile-mini-banner_1_-174219125648218.png',
		kli: 'https://cdn.shopify.com/s/files/1/2337/7003/files/mobile-mini-banner-174272158159005.png',
		lhr: 'https://cdn.shopify.com/s/files/1/2337/7003/files/mobile-mini-banner-lahore-only-174272148051741.png',
	},
});
const showBannerFor = computed(() => {
	if (storeType.value !== 'MARKETPLACE') {
		return false;
	}
	const date = new Date();
	const pakCutoff = new Date(
		globalCutoffConfig.value?.local_dates['pak_cutoff'],
	);
	const kliCutoff = new Date(
		globalCutoffConfig.value?.local_dates['kli_cutoff'],
	);
	const lhrCutoff = new Date(
		globalCutoffConfig.value?.local_dates['lhr_cutoff'],
	);
	if (date > pakCutoff) {
		if (date < kliCutoff) {
			return 'kli';
		} else if (date < lhrCutoff) {
			return 'lhr';
		}
	}
	return 'default';
});
const EidFomoTimerData = computed(() => {
	return {
		mobile_url:
			cdnImageUrls.value.mobile[
				showBannerFor.value as keyof typeof cdnImageUrls.value.mobile
			],
		desktop_url:
			cdnImageUrls.value.desktop[
				showBannerFor.value as keyof typeof cdnImageUrls.value.desktop
			],
		link: {
			name: 'LAAM',
			href: 'https://laam.pk',
			id: 1,
			entity: 'banner',
		},
	};
});

const country = computed(() => {
	return EU_COUNTRIES.includes(deliverToCountry.value)
		? 'EU'
		: deliverToCountry.value;
});

const endDate = computed(() => {
	if (!globalCutoffConfig) {
		return null;
	}
	if (country.value === 'PK') {
		const date = new Date();
		const pakCutoff = new Date(
			globalCutoffConfig.value?.local_dates?.['pak_cutoff'],
		);
		const kliCutoff = new Date(
			globalCutoffConfig.value?.local_dates?.['kli_cutoff'],
		);
		const lhrCutoff = new Date(
			globalCutoffConfig.value?.local_dates?.['lhr_cutoff'],
		);
		if (date > pakCutoff) {
			if (date < kliCutoff) {
				return kliCutoff;
			} else if (date < lhrCutoff) {
				return lhrCutoff;
			}
		}
	}
	if (
		globalCutoffConfig.value?.excluded_countries?.includes(
			deliverToCountry.value,
		)
	) {
		return null;
	}

	if (globalCutoffConfig.value?.[country.value]) {
		const countryData = globalCutoffConfig.value?.[country.value];
		const skynetCutoff = new Date(countryData['skynet']);
		const fedexCutoff = new Date(countryData['fedex']);
		const dhlCutoff = new Date(countryData['dhl']);
		const latestCutoff = [skynetCutoff, fedexCutoff, dhlCutoff].reduce(
			(max, d) => (d > max ? d : max),
		);
		return latestCutoff;
	} else {
		const targetDate = globalCutoffConfig.value?.['rest_of_world'];
		return new Date(targetDate);
	}
});
</script>

<template>
	<div class="homepage--components lg:py-8xl gap-xl lg:gap-10xl flex flex-col">
		<h1 class="visually-hidden">{{ store.store.name }}</h1>

		<MiniBanner
			v-if="
				globalCutoffConfig &&
				globalCutoffConfig.show_eid_fomo_banner &&
				storeType == 'MARKETPLACE' &&
				endDate !== null &&
				new Date(endDate) > new Date()
			"
			:data="EidFomoTimerData"
			:display-timer="
				globalCutoffConfig && globalCutoffConfig.show_eid_fomo_banner
			"
			:end-time="endDate"
			:section-index="-99"
		/>
		<template
			v-for="(page, idx) in pagesData!.pages"
			:key="pagesData!.pageParams[idx]"
		>
			<template
				v-for="(section, sectionIndex) in page.sections"
				:key="sectionIndex"
			>
				<HeroBanner
					v-if="section.component_identifier === 'hero-banner'"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				/>

				<EntityXProductsCarousel
					v-if="section.component_identifier === 'entity-x-products-carousel'"
					:metadata="cast<EntityXProductData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				/>

				<EntityXProductsGrid
					v-if="section.component_identifier === 'entity-x-products-grid'"
					:metadata="cast<EntityXProductData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				/>

				<EntityCarouselV2
					v-if="section.component_identifier === 'entity-carousel'"
					v-slot="{ entity, data: entityData, horizontalIndex }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<EntityCard
						:entity="entity"
						:data="entityData"
						:horizontal-index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: 'homepage',
						}"
						:component-info="{
							heading: section.meta_data.title || '',
							component: 'entity-carousel',
							input: section.meta_data.input,
							method: section.meta_data.method,
						}"
						:vertical-index="getVerticalIndex(idx, sectionIndex)"
						shape="rectangle"
					/>
				</EntityCarouselV2>

				<EntityGrid
					v-if="section.component_identifier === 'entity-grid'"
					v-slot="{ entity, data: entityData, horizontalIndex }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<EntityCard
						:entity="entity"
						:data="entityData"
						:horizontal-index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: 'homepage',
						}"
						:component-info="{
							heading: section.meta_data.title || '',
							component: 'entity-grid',
							input: section.meta_data.input,
							method: section.meta_data.method,
						}"
						:vertical-index="getVerticalIndex(idx, sectionIndex)"
						shape="rectangle"
					/>
				</EntityGrid>

				<EntityCarouselScrollable
					v-if="section.component_identifier === 'entity-carousel-scrollable'"
					v-slot="{ entity, data: entityData, horizontalIndex }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<EntityCard
						:entity="entity"
						:data="entityData"
						:horizontal-index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: 'homepage',
						}"
						:component-info="{
							heading: section.meta_data.title || '',
							component: 'entity-carousel-scrollable',
							input: section.meta_data.input,
							method: section.meta_data.method,
						}"
						:vertical-index="getVerticalIndex(idx, sectionIndex)"
						shape="square"
					/>
				</EntityCarouselScrollable>

				<ProductsCarouselV2
					v-if="section.component_identifier === 'products-carousel'"
					v-slot="{ horizontalIndex, product }"
					:metadata="cast<ProductsData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<ProductCard
						:data="product"
						:index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: ['homepage'],
							version: 'v0',
						}"
						:component-info="{
							heading: section.meta_data.title || '',
							component: 'products-carousel',
						}"
						page="homepage"
						:horizontal-index="horizontalIndex"
						:vertical-index="getVerticalIndex(idx, sectionIndex)"
						:is-hvc="Logan.isHvc"
					/>
				</ProductsCarouselV2>

				<ProductsGrid
					v-if="section.component_identifier === 'products-grid'"
					v-slot="{ horizontalIndex, product }"
					:metadata="cast<ProductsData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<ProductCard
						:data="product"
						:index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: ['homepage'],
							version: 'v0',
						}"
						:component-info="{
							heading: section.meta_data.title || '',
							component: 'products-grid',
						}"
						page="homepage"
						:horizontal-index="horizontalIndex"
						:vertical-index="getVerticalIndex(idx, sectionIndex)"
						:is-hvc="Logan.isHvc"
					/>
				</ProductsGrid>

				<CategoryGrid
					v-if="section.component_identifier === 'category-grid'"
					v-slot="{ index, data }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<CategoryCard
						v-if="data.href !== 'all' && data.image"
						:data="data"
						:image-src="data.image"
						:title="data.name"
						:horizontal-index="index"
						:vertical-index="getVerticalIndex(idx, sectionIndex)"
						:component-heading="section.meta_data.title || ''"
						:component-info="{
							heading: section.meta_data.title || '',
							component: 'category-grid',
							input: section.meta_data.input,
							method: section.meta_data.method,
						}"
						:data-source="{
							dataSource: 'manual',
							version: '',
							clickedFrom: 'homepage',
						}"
						shape="square"
					/>
				</CategoryGrid>

				<CategoryCarousel
					v-if="section.component_identifier === 'category-carousel'"
					v-slot="{ index, data }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<CategoryCard
						v-if="data.href !== 'all' && data.image"
						:data="data"
						:image-src="data.image"
						:title="data.name"
						:horizontal-index="index"
						:vertical-index="getVerticalIndex(idx, sectionIndex)"
						:component-heading="section.meta_data.title || ''"
						:data-source="{
							dataSource: 'manual',
							version: '',
							clickedFrom: 'homepage',
						}"
						:component-info="{
							heading: section.meta_data.title || '',
							component: 'category-carousel',
							input: section.meta_data.input,
							method: section.meta_data.method,
						}"
						shape="rectangle"
					/>
				</CategoryCarousel>

				<CategoryCarouselScrollable
					v-if="section.component_identifier === 'category-carousel-scrollable'"
					v-slot="{ index, data }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<CategoryCard
						v-if="data.href !== 'all' && data.image"
						:data="data"
						:image-src="data.image"
						:component-heading="section.meta_data.title || ''"
						:title="data.name"
						:horizontal-index="index"
						:vertical-index="getVerticalIndex(idx, sectionIndex)"
						:component-info="{
							heading: section.meta_data.title || '',
							component: 'category-carousel-scrollable',
							input: section.meta_data.input,
							method: section.meta_data.method,
						}"
						:data-source="{
							dataSource: 'manual',
							version: '',
							clickedFrom: 'homepage',
						}"
						shape="square"
					/>
				</CategoryCarouselScrollable>

				<CategoryGridScrollable
					v-if="section.component_identifier === 'category-grid-scrollable'"
					:data="section.meta_data.output"
					:metadata="cast<EntityData>(section.meta_data)"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				>
					<template #default="{ data, index }">
						<CategoryCard
							v-if="data.href !== 'all' && data.image"
							:data="data"
							:image-src="data.image"
							:title="data.name"
							:horizontal-index="index"
							:component-heading="section.meta_data.title || ''"
							:vertical-index="getVerticalIndex(idx, sectionIndex)"
							:data-source="{
								dataSource: 'manual',
								version: '',
								clickedFrom: 'homepage',
							}"
							:component-info="{
								heading: section.meta_data.title || '',
								component: 'category-grid-scrollable',
								input: section.meta_data.input,
								method: section.meta_data.method,
							}"
							shape="square"
						/>
					</template>
				</CategoryGridScrollable>
				<MiniBanner
					v-if="section.component_identifier === 'mini-banner'"
					:data="section.meta_data.output"
					:section-index="getVerticalIndex(idx, sectionIndex)"
				/>
			</template>
		</template>

		<div
			class="infinite_loader flex items-center justify-center text-gray-300"
			:class="{
				'p-lg': hasNextPage,
			}"
		>
			{{ hasNextPage ? 'Loading more sections...' : '' }}
		</div>
		<ClientOnly
			v-if="!hasNextPage && storeType == 'MARKETPLACE' && pageIdentifier"
		>
			<div>
				<TrendingProducts
					:node-id="
						nodeIdMapToIdentifier[
							pageIdentifier as keyof typeof nodeIdMapToIdentifier
						]
					"
					route-name="identifier"
				/>
			</div>
		</ClientOnly>
	</div>
</template>

<style>
.visually-hidden {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
	clip-path: inset(0px 0px 9% 9%);
	overflow: hidden;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0;
}
</style>
