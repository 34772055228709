<script setup lang="ts">
import type { WithClassAsProps } from './interface';
import { cn } from '@laam/lib/utils';
import { useCarousel } from './useCarousel';

defineOptions({
	inheritAttrs: false,
});

const props = defineProps<WithClassAsProps>();

const { carouselRef, orientation } = useCarousel();
</script>

<template>
	<div ref="carouselRef" class="overflow-hidden">
		<div
			:class="
				cn(
					'flex',
					orientation === 'horizontal' ? '-ml-xl' : '-mt-xl flex-col',
					props.class,
				)
			"
			v-bind="$attrs"
		>
			<slot />
		</div>
	</div>
</template>
