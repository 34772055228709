<script setup lang="ts">
import type { WithClassAsProps } from './interface';
import { cn } from '@laam/lib/utils';
import { useCarousel } from './useCarousel';
import { PhCaretRight } from '@phosphor-icons/vue';
import { IconButton } from '../icon-button';

const props = defineProps<WithClassAsProps>();

const { orientation, canScrollNext, scrollNext } = useCarousel();
</script>

<template>
	<IconButton
		v-if="canScrollNext"
		:class="
			cn(
				'hidden lg:flex touch-manipulation absolute size-9xl !rounded-full p-0 bg-white transition-all',
				'disabled:!bg-gray-50 disabled:opacity-50 disabled:!cursor-not-allowed disabled:!pointer-events-none disabled:shadow-none',
				orientation === 'horizontal'
					? 'right-2xl top-[35%] -translate-y-[35%]'
					: 'bottom-2xl left-1/2 -translate-x-1/2 rotate-90',
				props.class,
			)
		"
		variant="icon"
		@click.stop="scrollNext"
	>
		<slot>
			<PhCaretRight class="size-7xl text-current" />
			<span class="sr-only">Next Slide</span>
		</slot>
	</IconButton>
</template>
