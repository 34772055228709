<script setup lang="ts">
import {
	type BrandsResponse,
	type DropsResponse,
	type EntityData,
	type LinkNode,
	mapBrandsToLinkNodes,
	mapDropsToLinkNodes,
} from '@laam/cms-shared';
import EntityCard from './EntityCard.vue';
import { computed, inject, type Ref } from 'vue';
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '../base';

interface Props {
	metadata: EntityData;
	data: DropsResponse | BrandsResponse;
	sectionIndex: number;
}

const storeType = inject('storeType') as Ref<string>;

const props = defineProps<Props>();

if (!Array.isArray(props.data)) {
	console.log('EntityCarousel: data is not an array', {
		...props,
	});
}

const entities = computed<LinkNode[]>(() => {
	if (props.metadata.entity === 'brands') {
		return mapBrandsToLinkNodes(
			props.data as BrandsResponse,
			props.metadata as EntityData,
		);
	} else if (props.metadata.entity === 'drops') {
		return mapDropsToLinkNodes(props.data as DropsResponse, storeType.value);
	} else {
		throw new Error('Unsupported entity');
	}
});
</script>

<template>
	<div
		class="entity-carousel gap-3xl lg:gap-7xl lg:px-0 px-3xl flex flex-col items-center overflow-auto"
	>
		<div
			class="gap-sm flex flex-col w-full"
			:class="{
				'items-center': metadata.alignment === 'center',
				'items-start': metadata.alignment === 'left',
			}"
		>
			<h2 ref="title" class="text-lg font-semibold lg:display-xs text-gray-900">
				{{ metadata.title }}
			</h2>

			<h3 v-if="metadata.subtitle" class="text-sm font-normal text-gray-900">
				{{ metadata.subtitle }}
			</h3>
		</div>
		<Carousel
			class="cms--carousel-entity-base w-full overflow-hidden"
			:opts="{
				align: 'start',
				skipSnaps: true,
				duration: 15,
			}"
		>
			<CarouselContent>
				<CarouselItem
					v-for="(card, horizontalIndex) in entities"
					:key="horizontalIndex"
					class="basis-[45%] md:basis-1/4"
				>
					<slot
						:entity="metadata.entity"
						:data="card"
						:horizontal-index="horizontalIndex"
					>
						<EntityCard
							:entity="metadata.entity"
							:data="card"
							:index="horizontalIndex"
						/>
					</slot>
				</CarouselItem>
			</CarouselContent>
			<CarouselPrevious />
			<CarouselNext />
		</Carousel>
	</div>
</template>

<style>
.cms--carousel-entity-base .carousel__prev {
	@apply bg-white rounded-full top-[49%] p-md mx-3xl lg:p-lg lg:mx-5xl;
}

.cms--carousel-entity-base .carousel__next {
	@apply bg-white rounded-full top-[49%] p-md mx-3xl lg:p-lg lg:ml-5xl lg:mr-9xl;
}

.cms--carousel-entity-base .cms--carousel-drops__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.cms--carousel-entity-base .carousel__slide {
	@apply pr-3xl lg:pr-7xl items-start;
}

.cms--carousel-entity-base .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.cms--carousel-entity-base .carousel__pagination-item {
	@apply box-border;
}

.cms--carousel-entity-base .carousel__pagination-button {
	@apply p-none;
}

.cms--carousel-entity-base .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.cms--carousel-entity-base .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>
