<script setup lang="ts">
import { useWindowScroll, watchDebounced, watchThrottled } from '@vueuse/core';
const { $cathodeClient, $eventClient } = useNuxtApp();
import { vElementVisibility } from '@vueuse/components';
import { CategoryCard } from '@laam/ui/cms';
import type { DynamicInputData, LinkNode } from '@laam/cms-shared';

interface CategoryCardProps {
	horizontalIndex: number;
	verticalIndex: number;
	data: LinkNode;
	componentInfo?: {
		heading?: string;
		component?: string;
		input?: DynamicInputData;
		method?: string;
	};
	dataSource: {
		dataSource: string;
		version: string | undefined;
		clickedFrom: string;
	};
	shape: 'square' | 'rectangle';
}

const props = defineProps<CategoryCardProps>();

const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchThrottled(
	y,
	() => {
		hasWindowScrolled.value = true;
	},
	{ throttle: 200 },
);

function handleClickEvent() {
	$cathodeClient.queueEvent('entity_click', {
		source: window.location.href,
		page: 'homepage',
		href: props.data.href || '',
		clicked_from: 'homepage',
		entity: 'node',
		image: props.data.image || '',
		horizontal_index: props.horizontalIndex,
		vertical_index: props.verticalIndex,
		title: props.data.name,
		slotted: false,
		heading: props.componentInfo?.heading || '',
		component: props.componentInfo?.component || '',
		product_count: props.data.product_count || null,
		sale_percentage: 0,
		scroll_position: y.value,
		data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
		data_string: JSON.stringify(props.data),
		input:
			props.componentInfo?.method === 'dynamic'
				? props.componentInfo.input
				: undefined,
	});
	$eventClient.sendEvent('homepage-entity-click', {
		to: props.data.href,
		index: props.verticalIndex,
		title: props.data.name,
		heading: props.componentInfo?.heading || '',
		component: props.componentInfo?.component || '',
		entity: 'node',
		url: window.location.href,
	});
}

const isVisible = ref(false);
function onElementVisibility(state: boolean) {
	isVisible.value = state;
}
watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			const payload = {
				data: [
					{
						href: props.data.href,
						product_count: props.data.product_count || null,
						sale_percentage: 0,
						slotted: false,
						horizontal_index: props.horizontalIndex,
						data_string: JSON.stringify(props.data),
						image: props.data.image,
						title: props.data.name,
					},
				],
				input:
					props.componentInfo?.method === 'dynamic'
						? props.componentInfo.input
						: undefined,
				method: props.componentInfo?.method,
				vertical_index: props.verticalIndex,
				entity: 'node',
				heading: props.componentInfo?.heading || '',
				component: props.componentInfo?.component || '',
				scroll_position: y.value,
				source: window.location.href,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: '', // to be added by cathode
				clicked_from: '',
				page: 'homepage',
			};
			$cathodeClient.queueImpression('entity_impression', payload as any);
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);
defineOptions({ inheritAttrs: false });
</script>

<template>
	<NuxtLink
		:to="props.data.href"
		class="block w-full h-full"
		@click="handleClickEvent()"
	>
		<CategoryCard
			v-element-visibility="[onElementVisibility, { threshold: 0.8 }]"
			:data="data"
			:shape="shape"
			v-bind="$attrs"
		/>
	</NuxtLink>
</template>
